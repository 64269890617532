const items = [
  {
    link: '/despre-noi',
    text: 'Istoric',
  },
  {
    link: '/despre-noi/viziune-si-misiune',
    text: 'Viziune și misiune',
  },
  {
    link: '/despre-noi/strategie',
    text: 'Strategie',
  },
  {
    link: '/despre-noi/structura-organizatorica',
    text: 'Structura organizatorică',
  },
]
export default items

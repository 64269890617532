import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="STRATEGIE" items={items}>
      <SEO
        title="Strategie"
        keywords={[`viziune`, 'misiune', `fundația ebe`]}
      />
      <P>
        {' '}
        Lucrarea EBE este condusă de un grup indigen, dedicat echipării de
        lideri slujitori ucenicizatori, sub coordonarea unui Consiliu Director
        si al unei Echipe de Conducere. Un Consiliu Consultativ, format din
        misionari Entrust, asistă lucrarea EBE și participă la dezvoltarea
        lucrării de echipare prin programele EBE.
      </P>
      <P>
        EBE este o resursă care asistă pastori și slujitori din biserici și
        denominații evanghelice sa dezvolte Centre de Pregatire viabile, cu baza
        in Biserica Locală (CPBL). EBE asigură cursurile din programa sa, precum
        si consultantă prin personal specializat, încurajând bisericile si
        liderii din bisericile române să se implice în formarea de CPBL și în
        echiparea de lideri- slujitori.
      </P>
      <P>
        Lideri de biserici, pastori, evangheliști sau profesori la școli
        teologice, elevi si studenți, femei evlavioase, tineri cu chemare pentru
        lucrarea de slujire sau cu o inima pentru misiune, oameni care iubesc
        Cuvantul lui Dumnezeu, toți pot beneficia de pe urma acestei investitii
        care se numeste <b>EBE</b> – <b>E</b>ducatie <b>B</b>iblica prin{' '}
        <b>E</b>xtensie. Următoarele aspecte legate de strategie sunt destinate
        să descopere și să ajute formarea de noi lideri, să ducă la
        împuternicirea celor existenți și la abordarea cu maturitate a
        eforturilor misionare:
      </P>
      <P>
        Fiecare Sistem va dezvolta lucrarea prin colaborarea dintre
        Coordonatorul Național al Sistemului cu Coordonatorii Regionali din
        câmp. Aceștia împreună formează Echipa de Conducere a Sistemului
        respectiv. Coordonatorul Național de sistem conduce Echipa sistemului în
        activitatea de echipare a liderilor slujitori și cea de formare a noi
        CPBL în care să se implementeze și să se dezvolte o lucrare de echipare
        a ucenicilor sau a liderilor slujitori. Coordonatorii Regionali lansează
        și susțin viziunea de lucrare EBE în bisericile din zona lor geografică
        și își aduc aportul și la contextualizarea materialelor EBE, conducând
        astfel spre posibilitatea de a asigura o echipare relevantă prin
        pregătirea biblică în teritoriul de influiență.
      </P>
      <P>
        EBE asigură cursurile necesare în pregătirea biblică, prin curriculum
        propriu, precum și prin alte resurse de educație biblică.
      </P>
      <P>
        Echipa EBE promovează echiparea și încurajarea în slujire și în misiune
        nu doar prin filosofia de lucrare în grupuri mici ci și prin diferite
        conferințe destinate spre a motiva și dezvolta colaborarea cu diverși
        parteneri existenți sau potențiali. Pe măsură ce studenții sau ucenicii
        aflați în programele de echipare devin lideri-slujitori dezvoltați,
        personalul EBE asigură asistență în pregătirea pe care o fac aceștia cu
        alții (pregătirea de echipatori), conform 2Tim. 2:2. Colaborăm la
        trimiterea și susținerea misionarilor în diferite alte teritorii și
        culturi, în parteneriate cu biserici locale, cu agenții de misiune și cu
        instituții educaționale.
      </P>
      <P>
        Până la această dată sunt rezultate substanțiale în toate aceste domenii
        amintite.
      </P>
      <P>
        <b>Obiective majore</b>
      </P>
      <P>
        <ul>
          <li>
            <b>Fundatia EBE.</b> Întărirea si dezvoltarea organizației, cu
            impact în întreaga sferă evanghelică română. Asigurarea bazei de
            operare și a resurselor necesare prin proiectul economic de
            autosusținere.
          </li>
          <li>
            <b>CPBL.</b> Dezvoltarea CPBL existente si deschiderea de noi centre
            eficiente de pregătire și ucenicizare în bisericile din România și
            Diaspora, unde liderii să-și pregătească proprii membri pentru
            lucrarea de slujire.
          </li>
          <li>
            <b>O lucrare de slujire dezvoltată între femei.</b> Extinderea si
            integrarea lucrării cu femeile în Sistemele și Centrele de
            pregătire, cu respectarea structurilor proprii de autoritate de la
            nivelul fiecărei denominații.
          </li>
          <li>
            <b>Extinderea lucrării de misiune.</b> Motivarea bisericilor și a
            liderilor, asistarea lor in lucrarea de echipare și trimitere de
            misionari, în parteneriate cu alte organizații, cu o implicare
            proprie crescândă din partea Fundației EBE.
          </li>
          <li>
            <b>Resurse Educaționale.</b> Extinderea resurselor educaționale
            precum și revizuirea celor existente în vederea relevanței și
            contextualizării, astfel încît acestea să se adreseze nevoilor
            concrete ale bisericilor române.
          </li>
        </ul>
      </P>
    </PageWithLeftMenu>
  )
}
